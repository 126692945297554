import * as betprofessor from './Settings.styles.betprofessor';
import * as volcanobet from './Settings.styles.volcanobet';
import * as goldenrush from './Settings.styles.goldenrush';
import * as forsport from './Settings.styles.forsport';
import * as juicybet from './Settings.styles.juicybet';
import * as sterlingparker from './Settings.styles.sterlingparker';
import * as betroyale from './Setting.styles.betroyale';
import * as puntcity from './Settings.styles.puntcity';
import * as junglebet from './Setting.styles.junglebet';
import * as fiestabet from './Setting.styles.fiestabet';
import * as oldgill from './Setting.styles.oldgill';
import * as buffalobet from './Settings.styles.buffalobet';
import * as titanbet from './Settings.styles.titanbet';
import * as slambet from './Settings.styles.slambet';
import * as gigabet from './Settings.styles.gigabet';
import * as luckystride from './Settings.styles.luckystride';
import * as templebet from './Setting.styles.templebet';
import * as goldenbet888 from './Settings.styles.goldenbet888';
import * as hyperbet from './Settings.styles.hyperbet';
import * as bet777 from './Settings.styles.bet777';
import * as chromabet from './Settings.styles.chromabet';
import * as bet575 from './Settings.styles.bet575';
import * as betblast from './Settings.styles.betblast';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betprofessor,
  volcanobet,
  goldenrush,
  forsport,
  juicybet,
  sterlingparker,
  betroyale,
  puntcity,
  junglebet,
  fiestabet,
  oldgill,
  buffalobet,
  titanbet,
  slambet,
  gigabet,
  luckystride,
  templebet,
  goldenbet888,
  hyperbet,
  bet777,
  chromabet,
  bet575,
  betblast,
};
