import { CompetitionBySportV2Schema } from '@/views/sports/CompetitionsBySport/styles/CompetitionBySportV2.styles';

export const competitionBySportV2Styles: CompetitionBySportV2Schema = {
  containerNav: { mb: '3' },
  scrollButtonGroup: {
    mb: '2',
    variant: 'filter',
    sx: {
      '&&': {
        bg: 'transparent',
        boxShadow: 'none',
      },
    },
  },
  scrollButtonGroupDivider: {
    mb: '2',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    borderColor: 'blackAlpha.400',
  },
  competitionListBoxWrapper: {
    w: 'full',
  },
  scrollButtonGroupButtonCompetition: {
    color: 'white',
    borderColor: 'beta.500',
    sx: {
      '&[data-active]': {
        _after: {
          pos: 'relative',
          top: 'unset',
          right: '-2px',
        },
      },
    },
  },
  scrollButtonGroupButtonAllLeagues: {
    sx: {
      '&&': {
        bgGradient: 'linear(to-b, beta.500, beta.500)',
        color: 'alpha.800',
      },
      '&[data-active]': {
        _after: {
          pos: 'relative',
          top: 'unset',
          right: '-2px',
        },
      },
    },
  },
};
