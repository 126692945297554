import * as betprofessor from './BottomRow.styles.betprofessor';
import * as betroyale from './BottomRow.styles.betroyale';
import * as fiestabet from './BottomRow.styles.fiestabet';
import * as goldenrush from './BottomRow.styles.goldenrush';
import * as juicybet from './BottomRow.styles.juicybet';
import * as puntcity from './BottomRow.styles.puntcity';
import * as puntgenie from './BottomRow.styles.puntgenie';
import * as questbet from './BottomRow.styles.questbet';
import * as sterlingparker from './BottomRow.styles.sterlingparker';
import * as vikingbet from './BottomRow.styles.vikingbet';
import * as volcanobet from './BottomRow.styles.volcanobet';
import * as wellbet from './BottomRow.styles.wellbet';
import * as forsport from './BottomRow.styles.forsport';
import * as junglebet from './BottomRow.styles.junglebet';
import * as oldgill from './BottomRow.styles.oldgill';
import * as sugarcastle from './BottomRow.styles.sugarcastle';
import * as betgalaxy from './BottomRow.styles.betgalaxy';
import * as buffalobet from './BottomRow.styles.buffalobet';
import * as titanbet from './BottomRow.styles.titanbet';
import * as slambet from './BottomRow.styles.slambet';
import * as gigabet from './BottomRow.styles.gigabet';
import * as luckystride from './BottomRow.styles.luckystride';
import * as templebet from './BottomRow.styles.templebet';
import * as goldenbet888 from './BottomRow.styles.goldenbet888';
import * as hyperbet from './BottomRow.styles.hyperbet';
import * as bet777 from './BottomRow.styles.bet777';
import * as chromabet from './BottomRow.styles.chromabet';
import * as bet575 from './BottomRow.styles.bet575';
import * as betblast from './BottomRow.styles.betblast';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  buffalobet,
  betgalaxy,
  betprofessor,
  betroyale,
  fiestabet,
  goldenrush,
  juicybet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  wellbet,
  forsport,
  junglebet,
  oldgill,
  sugarcastle,
  titanbet,
  slambet,
  gigabet,
  luckystride,
  templebet,
  goldenbet888,
  hyperbet,
  bet777,
  chromabet,
  bet575,
  betblast,
};
