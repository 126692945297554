import { CSSObject } from '@chakra-ui/react';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';
import bg from '@/assets/luckystride/images/account/bannerBg.png';
import bgMb from '@/assets/luckystride/images/account/bannerBgMb.png';

export const bannerStyles: BannerSchema = {
  buttonDeposit: {
    variant: 'solid',
    borderRadius: 'md',
    pb: '1.5',
  },
};
export const FlexBannerContainer: CSSObject = {
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
  borderRadius: 'md',
  bgImage: [`url(${bgMb})`, null, `url(${bg})`],
  bgSize: 'cover',
  color: 'white',
  gap: '0',
  p: ['2', null, '3'],
  minH: '107px',
};
export const FlexAccountDetails: CSSObject = {
  alignItems: [null, null, 'center'],
  flexDir: ['column', null, 'row'],
  gap: '1.5',
  borderBottom: '1px dashed',
  borderBottomColor: 'blackAlpha.400',
};
export const FlexAccountName: CSSObject = {
  fontSize: 'lg',
  fontFamily: 'accent',
  fontWeight: 'normal',
  color: 'beta.300',
  textTransform: 'capitalize',
  textShadow: '0px 0px 8px rgba(254, 208, 119, 0.5)',
};
export const FlexAccountBalances: CSSObject = {
  gap: '8',
  mt: [null, null, '0'],
  mr: [null, null, '-2'],
  px: [null, null, '4'],
  py: [null, null, '2'],
};
export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
};
export const TextAccountBalanceLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'semibold',
  textTransform: 'capitalize',
  fontFamily: 'accent',
  textAlign: ['start', null, 'end'],
};
export const FlexAccountBalanceWrapper: CSSObject = {
  fontSize: 'md',
  fontFamily: 'accent',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 0px 8px rgba(254, 208, 119, 0.5)',
};
export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  display: 'inline',
  verticalAlign: 'super',
  color: 'beta.300',
});
export const TextAccountBalanceBaseUnit = (): CSSObject => ({
  color: 'beta.300',
});
export const TextAccountBalanceSubUnit = (): CSSObject => ({
  color: 'beta.300',
});
export const FlexButtonContainer: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '2',
  borderTop: '1px dashed',
  borderTopColor: 'whiteAlpha.300',
  pt: '2',
};
export const TextButton: CSSObject = {
  h: '4',
  fontFamily: 'accent',
  pt: '0.5',
  textTransform: 'uppercase',
};
export const FlexBalanceBetInfo: CSSObject = {
  borderRadius: 'md',
  border: '2px',
  borderColor: 'white',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  alignItems: 'center',
  h: '10',
  justifyContent: 'center',
  flex: ['1', null, '0'],
  px: '4',
  py: '3',
  whiteSpace: [null, null, 'nowrap'],
};
export const FlexLabel: CSSObject = {
  flexDirection: ['column', null, 'row'],
};
