import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Flex, Icon } from '@chakra-ui/react';
import { ArrowDownCircle } from '@styled-icons/bootstrap/ArrowDownCircle';
import { TBetSlipBet } from '@/components/Betslip/Services/Betslip.types';
import api from '@/api/api';
import { Button } from '@/components/Button/Button';
import { formatStake, getStrings } from '@/helpers/utils';
import {
  BoxReviewStakeContainer,
  ReviewContainer,
  ReviewEst,
  ReviewEstText,
  ReviewStake,
  ReviewStakeText,
} from '../Forms/Forms.styles';
import { PendingBet } from './PendingBet';
import { BetRejectedText, RejectedBetContainer } from './Footer.styles';

const BET_PRE_APPROVAL = 'punter/bet-slip/bet-request';

async function approveOrRejectPreApprovedBet(
  requestId: string,
  approved: boolean
) {
  try {
    await api
      .put(BET_PRE_APPROVAL, [
        {
          bet_request_id: requestId,
          status: approved ? 'Approved' : 'Rejected',
        },
      ])
      .then((r) => r.data);
  } catch (e) {
    console.error(e);
  }
}

export type TPreApprovalProps = {
  bet: TBetSlipBet;
};
export function PreApproval({ bet }: TPreApprovalProps) {
  if (!bet.confirmation?.pre_approved_stake) {
    return <PendingBet />;
  }

  const [{ Generic }] = getStrings();
  const isFixedOdds =
    bet.price_type !== 'starting' &&
    bet.price_type !== 'tote_single_mid' &&
    bet.price_type !== 'tote_single_best';

  return (
    <Box>
      <Box mb={4}>
        <RejectedBetContainer>
          <Flex justify="center" w="full" alignItems="center">
            <Icon as={ArrowDownCircle} boxSize="6" pr="1" />
            <BetRejectedText>Revised offer</BetRejectedText>
          </Flex>
        </RejectedBetContainer>
      </Box>
      <ReviewContainer>
        <BoxReviewStakeContainer>
          <ReviewStakeText>{Generic.Stake}</ReviewStakeText>
          <ReviewStake>
            {formatStake(bet.confirmation?.pre_approved_stake / 100)}
          </ReviewStake>
        </BoxReviewStakeContainer>

        <Box pt="2">
          <ReviewEstText>Est Payout</ReviewEstText>
          <ReviewEst>
            {isFixedOdds ? (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              formatStake(bet?.confirmation.pre_approved_stake ?? 0)
            ) : (
              <FormattedMessage id="generic.na" />
            )}
          </ReviewEst>
        </Box>
      </ReviewContainer>
      <Flex justify="space-between" gap="4">
        <Button
          type="button"
          w="full"
          onClick={() => approveOrRejectPreApprovedBet(bet.request_id, true)}
        >
          Accept
        </Button>
        <Button
          variant="outline"
          type="button"
          w="full"
          onClick={() => approveOrRejectPreApprovedBet(bet.request_id, false)}
        >
          Reject
        </Button>
      </Flex>
    </Box>
  );
}
